export enum EnumAppMenu {
    Initialize = "initialize",
    Error = "error",
    Login = "login",
    Recovery = "recovery",
    Dashboard = "dashboard",
    Notifications = "notifications/list",
    Settings = "settings",
    Account = "account",

    Content = "content/list",
    ContentAdd = "content/list/add",
    ContentEdit = "content/list/edit",
    ContentView = "content/list/view",

    News = "news/list",
    NewsAdd = "news/list/add",
    NewsEdit = "news/list/edit",
    NewsView = "news/list/view",

    Campaigns = "campaigns/list",
    CampaignsView = "campaigns/list/view",
    CampaignsEdit = "campaigns/list/edit",
    CampaignsAdd = "campaigns/list/add",

    Categories = "categories/list",
    CategoriesView = "categories/list/view",
    CategoriesEdit = "categories/list/edit",
    CategoriesAdd = "categories/list/add",

    Benefits = "benefits/list",
    BenefitsView = "benefits/list/view",
    BenefitsEdit = "benefits/list/edit",
    BenefitsAdd = "benefits/list/add",

    Merchants = "merchants/list",
    MerchantsView = "merchants/list/view",
    MerchantsEdit = "merchants/list/edit",
    MerchantsAdd = "merchants/list/add",

    MerchantsConsumptions = "merchants/consumptions/list",
    MerchantsConsumptionsView = "merchants/consumptions/list/view",

    MerchantsApplications = "merchants/applications",
    MerchantsApplicationsView = "merchants/applications/view",
    MerchantsApplicationsList = "merchants/applications/list",

    MerchantsMovements = "merchants/movements/list",
    MerchantsMovementsView = "merchants/movements/list/view",
    MerchantsMovementsEdit = "merchants/movements/list/edit",
    // MerchantsMovementsAdd = "merchants/movements/add",
    MerchantsMovementsNew = "merchants/movements/new",

    MerchantsMyStores = "merchants/mystores",
    MerchantsMyStoresView = "merchants/mystores/view",
    MerchantsMyStoresEdit = "merchants/mystores/edit",

    ConsumersWallet = "consumers/list",
    ConsumersWalletAdd = "consumers/list/add",
    ConsumersWalletView = "consumers/list/view",
    ConsumersWalletEdit = "consumers/list/edit",

    ConsumersWalletLoad = "consumers/load/list",
    ConsumersWalletLoadAdd = "consumers/load/add",
    ConsumersWalletLoadView = "consumers/load/view",
    ConsumersWalletLoadEdit = "consumers/load/edit",

    ConsumersWalletPending = "consumers/pending/list",
    ConsumersWalletPendingView = "consumers/pending/list/view",

    ConsumersCard = "consumers/list",
    ConsumersCardAdd = "consumers/list/add",
    ConsumersCardView = "consumers/list/view",
    ConsumersCardEdit = "consumers/list/edit",

    Configurations = "configurations/general",
    ConfigurationsMenuView = "configurations/general/menu/view",
    ConfigurationsMenuEdit = "configurations/general/menu/edit",
    ConfigurationsEditionView = "configurations/general/edition/view",
    ConfigurationsEditionEdit = "configurations/general/edition/edit",
    ConfigurationsMenuAdd = "configurations/general/menu/add",
    ConfigurationsEditionAdd = "configurations/general/edition/add",
    ConfigurationsPermissionAdd = "configurations/general/permission/add",
    ConfigurationsPermissionView = "configurations/general/permission/view",
    ConfigurationsPermissionEdit = "configurations/general/permission/edit",
    ConfigurationsSystemOCR = "configurations/ocr",

    UsersList = "users/list",
    UsersAdd = "users/list/add",
    UsersView = "users/list/view",
    UsersEdit = "users/list/edit",

}

export enum CONFIGURATIONSTABSEGMENTSIDS {
    EDITION = 1,
    MENU = 2,
    PERMISSIONS = 3
}

export interface IAppMenu {
    Title: string,
    Url: string,
    Icon: string
}
export interface IMenusModel {

    // Children?: IMenusModel[];
    // IdParent: number;
    // IsSingleData: boolean;
    // Name: string;
    // NavigationBackgroundColor: string;
    // NavigationIcon: string;
    // NavigationPath: string;
    // NavigationPosition: number;
    // NavigationTextColor: string;
    // Parent: any;
    // Id: string;
    // RegistDate?: string;
    // RegistUser?: number;
    // Module: any;
    Position?: number;
    // IdPlatform?: number;
    QueryParams?: any;
    // IsPublic?: boolean;


    Id: string;
    IdEntity?: string;
    IdModule?: number;
    IdPlatform: number;
    Name?: string;
    IdParent?: string;
    IsSingleData?: boolean;
    NavigationPath?: string;
    NavigationIcon?: string;
    NavigationPosition?: number;
    NavigationBackgroundColor?: string;
    NavigationTextColor?: string;
    IsTabNavigation?: boolean;
    IsPublic?: boolean;
    Module?: IMenusModuleModel,
    Parent?: IMenusModel,
    Children?: IMenusModel[],
    CreateDate?: string;
    PermittedOperationList?: number[];

}

export interface IMenusModuleModel {
    Id: number;
    IdParent: string;
    ShowOnDashboard: boolean;
    ForDataManagement: boolean;
    Name: string;
    OperationList: number[];
    CreateDate: string;
}
export interface IPlatformMenusModel {
    Menus: IMenusModel[],
    Platform: any
}

export interface IMenuTabModel extends IMenusModel {
    IdTab: number;
}

export class MenuTabModel implements IMenuTabModel {

    Id: string;
    IdPlatform: number;
    IsTabNavigation?: boolean;
    Module?: IMenusModuleModel;
    NavigationPath?: string;
    NavigationPosition?: number;
    Name?: string;

    IdTab: number;

    constructor(data: Partial<IMenuTabModel> = {}) {

        this.Id = data?.Id || null;
        this.IdPlatform = data?.IdPlatform || null;
        this.IsTabNavigation = data?.IsTabNavigation || null;
        this.Module = data?.Module || null;
        this.NavigationPath = data?.NavigationPath ? ("/" + data?.NavigationPath) : '/';
        this.NavigationPosition = data?.NavigationPosition || null;
        this.Name = data?.Name || '';

        // console.log(this.NavigationPath);


        if (this.NavigationPath) {
            const statecopy = new URL(window.location.origin + this.NavigationPath.slice(0, this.NavigationPath.length));
            const queryparams = Object.fromEntries(statecopy.searchParams as any);

            this.IdTab = queryparams.id ? parseInt(queryparams.id) : null;
        }
        else
            this.IdTab = null;

    }
}