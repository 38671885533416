import { NotificationsService } from '@services/notifications.service';
import { ProjectService } from '@services/project.service';

import { EnumAppMenu } from '@models/AppMenus';
import { Subscription } from 'rxjs';
import { SessionService } from '@services/session.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { IUserInfoModel } from '@models/User';

@Component({
    selector: 'side-menu-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class SideMenuHeaderComponent implements OnInit, OnDestroy {

    public User: IUserInfoModel;

    public ProjectLogo: string = "";

    private subs: Subscription[] = [];

    public NotificationPath: string = EnumAppMenu.Notifications;

    public HomePath: string = EnumAppMenu.Dashboard;

    public CounterNotifications: number = 0;

    constructor(private SessionService: SessionService,
        private ProjectService: ProjectService,
        private NotificationsService: NotificationsService) { }

    ngOnInit() {

        const subSession = this.SessionService.Session.subscribe(session => {

            this.User = session?.UserData;

            this.ProjectLogo = this.ProjectService.GetSettings()?.Project?.Logos?.Header || '';

            // console.log(this.User);

        });

        const subCounterNotifications = this.NotificationsService.NotificationsCount.subscribe(counterNotifications => {
            this.CounterNotifications = counterNotifications;

            console.log("header notifications: ", this.CounterNotifications);

        });

        this.subs.push(...[subSession, subCounterNotifications]);

    }

    ngOnDestroy(): void {
        if (this.subs)
            this.subs.forEach(sub => sub.unsubscribe());
    }


}
