<ion-header>
    <ion-toolbar class="ion-padding-end">
        <ion-button style="margin-right: -16px;" fill="clear" color="dark" shape="round" slot="end" (click)="Close()">
            <ion-icon iconMode slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>

    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding padding-horizontal">

        <ion-grid>
            <ion-row>
                <ion-col>
                    <ion-item lines="none" fill="outline">
                        <ion-label position="stacked" i18n=":@@modal_notification.subject">Assunto</ion-label>
                        <ion-input [value]="Data?.Subject" readonly="true"></ion-input>
                    </ion-item>
                </ion-col>

                <ion-col>
           
                    <ion-item lines="none" fill="outline">
                        <ion-label position="stacked" i18n=":@@modal_notification">Data</ion-label>
                        <ion-input [id]="'datepicker-news-date'" inputmode="none"
                            [value]="Data?.CreateDate|date:'dd/MM/YYYY'" readonly="true">
                            <ion-icon name="calendar-outline"></ion-icon>
                        </ion-input>
                    </ion-item>
                </ion-col>
            </ion-row>

            <ion-row>
                <ion-col>
                    <ion-item class="full" lines="full" fill="outline">
                        <ion-label position="stacked" i18n=":@@modal_notification.message">Mensagem
                        </ion-label>
                        <ion-textarea style="min-height: 200px;" [innerHTML]="Data?.Message" readonly="true"></ion-textarea>

                    </ion-item>
                </ion-col>
            </ion-row>

            <ion-row>
                <ion-col>
                    <ion-item class="full" lines="full" fill="outline">

                        <ion-label class="label-checkbox" i18n=":@@modal_notification.checkbox"> Marcar como não
                            lida</ion-label>
                        <ion-checkbox slot="start" [checked]="MarkHasntView" (ionChange)="OnChangeCheckbok()"
                            slot="start"></ion-checkbox>

                    </ion-item>
                </ion-col>
            </ion-row>

        </ion-grid>
</ion-content>