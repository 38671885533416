import { ProjectService } from '@services/project.service';
import { GoogleMapsService } from '@services/google-maps.service';
import { Component, Input, OnInit, ViewChild, OnChanges, Output, EventEmitter } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapKmlLayer, MapMarker } from '@angular/google-maps';
import { IProjectSettings } from '@models/ProjectSettings';

@Component({
    selector: 'google-map-container',
    templateUrl: './google-map-container.component.html',
    styleUrls: ['./google-map-container.component.scss'],
})
export class GoogleMapContainerComponent implements OnInit, OnChanges {

    @Input('class') Class: string = "";

    @Input() id: string = "";

    @Input('options') OptionsInit: google.maps.MapOptions;

    @Input('pins') MarkersLocations: any[] = [] as any;

    @Input('polygonUrl') PolygonUrl: any = null;

    // ??? talvez seja necessario rever
    @Input('reset') Reset: boolean = false;

    public Markers: any[] = [] as any;

    @Output('change') ChangeMarkersLocation: EventEmitter<any[]> = new EventEmitter<any[]>();

    @ViewChild(GoogleMap, { static: false }) Map!: GoogleMap;

    // @ViewChild(MapInfoWindow, { static: false }) info!: MapInfoWindow;

    // infoContent: string = '';

    public Options: google.maps.MapOptions;

    @ViewChild('kmlLayer') KmlLayerContainer: MapKmlLayer;

    private mapStarted: boolean = false;

    private projectSettings: IProjectSettings = null;


    constructor(private GoogleMapsService: GoogleMapsService, private ProjectService: ProjectService) {

    }

    ngOnInit() {

        this.projectSettings = this.ProjectService.GetSettings();
        // console.log("google map container -> ", this.id, this.Map, this.Markers);

        // this.loadMap();
    }

    ngOnChanges(ev) {

        // console.log(ev, this.id, this.Map, this.Markers);

        // console.log("reset -> ", this.Reset)

        // ??? talvez seja necessario rever
        if (!this.Reset)
            this.loadMap();
        else {
            // ??? talvez seja necessario rever
            this.Markers.forEach(marker => {
                this.MarkersLocations[marker.options.data.index] = { Latitude: marker.position.lat, Longitude: marker.position.lng }
            })
        }
    }

    private parseMarker(marker, index: number = 0): any {

        console.log(marker);

        if (!marker?.Latitude || !marker?.Longitude)
            marker = this.projectSettings?.Coordinates;

        return {
            title: '',
            options: {
                draggable: this.OptionsInit.gestureHandling != 'none',
                data: {
                    index: index,
                    original: new google.maps.LatLng(marker?.Latitude || '', marker?.Longitude || '').toJSON()
                }
            },
            position: new google.maps.LatLng(marker?.Latitude || '', marker?.Longitude || '').toJSON()
        };
    }

    private loadMap() {

        const mapStyle = this.GoogleMapsService.GetMapStyle();

        this.Options = this.OptionsInit ? JSON.parse(JSON.stringify(this.OptionsInit)) : {};
        this.Options['styles'] = mapStyle;

        if (this.MarkersLocations?.length > 0)
            this.Markers = this.MarkersLocations.map((i, index) => this.parseMarker(i, index));
        else
            this.Markers = this.parseMarker(this.projectSettings?.Coordinates, 1);

        if (this.PolygonUrl && !this.mapStarted) {

            const center = this.KmlLayerContainer.kmlLayer.getMap().getCenter().toJSON();
            console.log(center);
            this.Options['center'] = center;
        }

        if (this.Markers && this.Markers.length > 0 && this.Markers[0].position.lat) {
            this.Options['center'] = { lat: this.Markers[0].position.lat, lng: this.Markers[0].position.lng }
        }


        // obter localização atual do utilizador
        // navigator.geolocation.getCurrentPosition((position) => {

        // 	console.log(position);

        // 	// if (!this.Options)
        // 	// 	this.Options.center = {
        // 	// 		lat: position.coords.latitude,
        // 	// 		lng: position.coords.longitude,
        // 	// 	}
        // })

        // this.Map = this.GoogleMapsService.GetMap();

        // this.mapStyle = this.GoogleMapsService.GetMapStyle();

        // this.MapContainer?.mapTypes.set('styled_map', this.mapStyle);
    }

    OnOpenInfo(marker: MapMarker, content: string) {
        console.log(marker, content);

        // this.infoContent = content;
        // this.info.open(marker)
    }

    OnDrag(ev: any, marker) {

        // ??? talvez seja necessario rever
        // update position original marker
        this.MarkersLocations[marker.options.data.index] = { Latitude: ev.latLng.lat(), Longitude: ev.latLng.lng() }

    }
}
