import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from '@environments/environment';
import { firstValueFrom, Observable, Subscription } from 'rxjs';

export enum EnumAPIStatus {
    InternalServerError = 0,
    Success = 1,
    NotFound = 2,
    NoData = 3,
    DuplicatedData = 4,
    InvalidData = 5,
    InvalidRequest = 6,
    InvalidProccess = 7,
    DependenciesRestriction = 8,
    InvalidAccessToken = 9,
    Unauthorized = 10
}

export enum EnumAPIErrorMessage {
    InternalServerError = "Ocorreu um erro ao realizar a operação, problema no servidor.",
    Success = "Operação realizada com sucesso.",
    NotFound = "Operação não foi encontrada.",
    NoData = "Sem dados.",
    DuplicatedData = "Ocorreu um erro, dados duplicados.",
    InvalidData = "Ocorreu um erro, dados inválidos.",
    InvalidRequest = "Ocorreu um erro, o pedido não é válido.",
    InvalidProccess = "Ocorreu um erro ao executar a operação.",
    DependenciesRestriction = "Ocorreu um erro ao realizar a operação.",
    InvalidAccessToken = "A sua sessão expirou.",
    Unauthorized = "Operação não autorizada."
}
export interface IAPIResponse<T> {
    Result: T,
    Status: number,
    Error: { Message: string, Details?: { Error: string, Field: any }[] },
    // Error: string,
    IsSuccessStatus: boolean
}

export interface IAPIPaginationResponse<T> extends IAPIResponse<T> {
    TotalRegists?: number,
    HasMorePages?: boolean,
    TotalPages?: number
}
export interface IAPIResponseOk {
    Message?: string,
    Ok?: boolean,
    Value?: any,
    Tipo?: 0 | 1 | 2
}

export class APIResponseModel<T> implements IAPIPaginationResponse<T> {
    Result: T;
    Status: number;
    Error: { Message: string, Details?: { Error: string, Field: any }[] };
    IsSuccessStatus: boolean;
    TotalRegists?: number;
    HasMorePages?: boolean;
    TotalPages?: number;

    constructor(data: Partial<IAPIPaginationResponse<T>> = {}) {

        this.Error = data?.Error || { Message: null, Details: null };
        this.Status = data?.Status || EnumAPIStatus.InternalServerError;
        this.Result = data?.Result || null;
        this.IsSuccessStatus = data?.IsSuccessStatus ?? false;

        if (data?.TotalRegists) {
            this.TotalRegists = data?.TotalRegists || 0;
            this.HasMorePages = data?.HasMorePages || false;
            this.TotalPages = data?.TotalPages || 0;
        }

        if (!this.Error?.Message) {

            switch (this.Status) {
                case EnumAPIStatus.InternalServerError:
                    this.Error.Message = EnumAPIErrorMessage.InternalServerError;
                    break;
                case EnumAPIStatus.Success:
                    this.Error.Message = EnumAPIErrorMessage.Success;
                    break;
                case EnumAPIStatus.NotFound:
                    this.Error.Message = EnumAPIErrorMessage.NotFound;
                    break;
                case EnumAPIStatus.NoData:
                    this.Error.Message = EnumAPIErrorMessage.NoData;
                    break;
                case EnumAPIStatus.DuplicatedData:
                    this.Error.Message = EnumAPIErrorMessage.DuplicatedData;
                    break;
                case EnumAPIStatus.InvalidData:
                    this.Error.Message = EnumAPIErrorMessage.InvalidData;
                    break;
                case EnumAPIStatus.InvalidRequest:
                    this.Error.Message = EnumAPIErrorMessage.InvalidRequest;
                    break;
                case EnumAPIStatus.InvalidProccess:
                    this.Error.Message = EnumAPIErrorMessage.InvalidProccess;
                    break;
                case EnumAPIStatus.DependenciesRestriction:
                    this.Error.Message = EnumAPIErrorMessage.DependenciesRestriction;
                    break;
                case EnumAPIStatus.InvalidAccessToken:
                    this.Error.Message = EnumAPIErrorMessage.InvalidAccessToken;
                    break;
                case EnumAPIStatus.Unauthorized:
                    this.Error.Message = EnumAPIErrorMessage.Unauthorized;
                    break;
                default:
                    this.Error.Message = EnumAPIErrorMessage.InternalServerError;
                    break;
            }
        }
        if (this.Error?.Details && this.Error?.Details.length > 0) {
            const errorsMessages = [];

            this.Error?.Details?.forEach(element => {
                errorsMessages.push(element.Error);
            });

            this.Error.Message = '<b>' + this.Error.Message + '</b> </br></br>' + errorsMessages.join("; </br>");


        }

        console.log(this.Error);
    }
}

export abstract class AbstractAPI {
    private headers: HttpHeaders;

    private endpoint: string;

    // public Global;

    private logger: boolean = false

    constructor(protected HttpClient: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Basic ' + null, 'Menu': '', "Entity": '' });
    }

    public async Init() {

        this.endpoint = environment.Integration.API.URL || "API endpoint não definido.";

        // this.Global = {
        //     idUser: environment.Global.IdUser,
        //     // idEntity: environment.Global.IdEntity,
        //     idEdition: null
        // };

        return this.endpoint;
    }

    /**
     * Atribui token de sessao.
     * @param token string token
     */
    public SetAuthorization(token: string = "") {

        this.headers = this.headers.delete('Menu', '0');

        token
            ? this.headers = this.headers.set('Authorization', 'Basic ' + token)
            : this.headers = this.headers.delete('Authorization', 'Basic');

        return this.headers;
    }
    /**
     * 
     * @param id    
     * @returns 
     */
    public SetEntityId(id: string = "") {
        // debugger

        console.log("ID ENTITY", id);

        id
            ? this.headers = this.headers.set('Entity', String(id))
            : this.headers = this.headers.delete('Entity', '');

        return this.headers;
    }

    /**
     * 
     * @param idMenu 
     * @returns 
     */
    public SetMenu(idMenu: string = '') {

        if (this.logger)
            console.log(idMenu);

        idMenu
            ? this.headers = this.headers.set('Menu', idMenu)
            : this.headers = this.headers.set('Menu', 'null');

        return this.headers;
    }

    /**
     * 
     * @param idModule 
     * @returns 
     */
    public SetModule(idModule: string | number = '') {

        if (this.logger)
            console.log(idModule);

        idModule
            ? this.headers = this.headers.set('IdModule', String(idModule))
            : this.headers = this.headers.set('IdModule', 'null');

        return this.headers;
    }
    /**
     * Metodo generico de requests POST.
     * Gere o pedido e possíveis respostas de erro, mostrando um alerta nessa situação.
     * @param method Endpoint final do pedido ao webservice
     * @param params Objecto de parametros do metodo POST
     */
    protected RequestPOST<T>(method: string, params?: any): Promise<any> {

        if (!this.endpoint) return;

        const http = this.HttpClient.post<IAPIResponse<T>>(`${this.endpoint}${method}`, params, { headers: this.headers });

        return firstValueFrom(http)
            .then(response => {
                if (this.logger)
                    console.log(`Result POST ${method}: `, response);

                return new APIResponseModel(response);
            })
            .catch(error => {
                if (this.logger)
                    console.log(`Error POST ${method}: `, error);

                return new APIResponseModel({ Error: { Message: error?.message || '' } });
            });

    }

    /**
     * Metodo generico de requests GET.
     * Gere o pedido e possíveis respostas de erro, mostrando um alerta nessa situação.
     * @param path Endpoint do pedido ao webservice
     */
    protected RequestGET<T>(method: string): Promise<any> {

        if (!this.endpoint) return;

        const http = this.HttpClient.get<IAPIResponse<T>>(`${this.endpoint}${method}`, { headers: this.headers });

        return firstValueFrom(http)
            .then(response => {
                if (this.logger)
                    console.log(`Result GET ${method}: `, response);

                return new APIResponseModel(response);
            })
            .catch(error => {
                if (this.logger)
                    console.log(`Error GET ${method}: `, error);

                return new APIResponseModel({ Error: { Message: error?.message || '' } });
            });

    }

    /**
    * Metodo generico de requests GET.
    * Gere o pedido e possíveis respostas de erro, mostrando um alerta nessa situação.
    * @param path Endpoint do pedido ao webservice
    */
    protected RequestFilesPOST<T>(method: string, params?: any): Promise<IAPIResponse<T>> {

        if (this.logger)
            console.log(method, params);


        if (!this.endpoint) return;

        return new Promise(async (resolve) => {
            let result;

            try {

                const req = new HttpRequest('POST', `${this.endpoint}${method}`, params, {
                    headers: new HttpHeaders({
                        'Authorization': this.headers.get('Authorization'),
                        'Entity': this.headers.get('Entity'),
                        'Menu': this.headers.get('Menu')
                    }),
                    reportProgress: true,
                    responseType: 'json'
                });

                const subhttp: Subscription = await this.HttpClient.request(req).subscribe({
                    next: (response) => {
                        result = response['body'] || null;
                    },
                    error: (error) => {
                        if (subhttp) subhttp.unsubscribe();
                        resolve(error);
                    },
                    complete() {
                        if (subhttp) subhttp.unsubscribe();
                        resolve(new APIResponseModel(result));
                    }
                });

                // resolve(new APIResponseModel({ Error: { Message: null } }))

            } catch (error) {
                resolve(new APIResponseModel({ Error: { Message: error?.message || '' } }));
            }

            // try {
            //     const subhttp: Subscription = this.HttpClient.post<IAPIResponse<T>>(`${this.endpoint}${method}`, params, { headers: { 'Authorization': this.headers.get('Authorization'), 'Menu': this.headers.get('Menu') }, reportProgress: true, observe: 'events' })
            //         .subscribe({
            //             next: (response) => {
            //                 result = response['body'] || null;
            //             },
            //             error: (error) => {
            //                 // if (subhttp) subhttp.unsubscribe();
            //                 resolve(error);
            //             },
            //             complete() {
            //                 // if (subhttp) subhttp.unsubscribe();
            //                 resolve(result);
            //             }
            //         });

            // } catch (error) {
            //     resolve(new APIResponseModel({ Error: { Message: error } }));
            // }

        })

    }

    /**
     * Metodo generico de requests PUT.
     * Gere o pedido e possíveis respostas de erro, mostrando um alerta nessa situação.
     * @param path Endpoint do pedido ao webservice
     * @param params Objecto de parametros do metodo PUT
     */
    protected RequestPUT<T>(method: string, params?: any): Promise<IAPIResponse<T>> {

        if (!this.endpoint) return;

        const http = this.HttpClient.put<IAPIResponse<T>>(`${this.endpoint}${method}`, params, { headers: this.headers });

        return firstValueFrom(http)
            .then(response => {
                if (this.logger)
                    console.log(`Result PUT ${method}: `, response);

                return new APIResponseModel(response);
            })
            .catch(error => {
                if (this.logger)
                    console.log(`Error PUT ${method}: `, error);

                return new APIResponseModel({ Error: { Message: error?.message || '' } });
            });
    }

    /**
     * Metodo generico de requests DEL.
     * Gere o pedido e possíveis respostas de erro, mostrando um alerta nessa situação.
     * @param path Endpoint do pedido ao webservice
     */
    protected RequestDELETE<T>(method: string): Promise<IAPIResponse<T>> {

        if (!this.endpoint) return;

        const http = this.HttpClient.delete<IAPIResponse<T>>(`${this.endpoint}${method}`, { headers: this.headers });

        return firstValueFrom(http)
            .then(response => {
                if (this.logger)
                    console.log(`Result DELETE: `, response);

                return new APIResponseModel(response);
            })
            .catch(error => {
                if (this.logger)
                    console.log(`Error DELETE: `, error);

                return new APIResponseModel({ Error: { Message: error?.message || '' } });
            });
    }

    /**
     * 
     * @param method 
     * @param params 
     * @returns 
     */
    protected RequestPOSTDownloadFile<T>(method: string, params: any = null): Observable<any> {
        if (this.logger)
            console.log(method, this.endpoint);

        if (!this.endpoint) return;

        return this.HttpClient.post<Blob>(`${this.endpoint}${method}`, params, { headers: { 'Authorization': this.headers.get('Authorization'), 'Menu': this.headers.get('Menu'), 'Entity': this.headers.get('Entity') }, reportProgress: true, observe: 'response', 'responseType': 'blob' as 'json' });

        // 'Access-Control-Expose-Headers': 'Content-Disposition'

    }

    /**
     * 
     * @param method 
     * @param params 
     * @returns 
     */
    protected RequestGETDownloadFile<T>(method: string, params: any = null): Observable<any> {
        if (this.logger)
            console.log(method, this.endpoint);

        if (!this.endpoint) return;

        return this.HttpClient.get<Blob>(`${this.endpoint}${method}`, { headers: { 'Authorization': this.headers.get('Authorization'), 'Menu': this.headers.get('Menu'), 'Entity': this.headers.get('Entity') }, reportProgress: true, observe: 'response', 'responseType': 'blob' as 'json' });

        // 'Access-Control-Expose-Headers': 'Content-Disposition'

    }
}