import { ProjectService } from '@services/project.service';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject, Subscription, firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

import EmbedJSONMapStyle from '@assets/maps/style.json';
import EmbedJSONMapStyleDark from '@assets/maps/style_dark.json';
import EmbedJSONMapStyleLight from '@assets/maps/style_light.json';

//https://github.com/angular/components/tree/main/src/google-maps#readme

export enum EnumMapStyle {
    DEFAULT = 'default',
    LIGHT = 'light',
    DARK = 'dark'
}

@Injectable({
    providedIn: 'root'
})
export class GoogleMapsService {

    private ApiLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private apiLoaded = this.ApiLoaded.asObservable();

    private loadedGoogleMaps: any;

    private googleMapsUrl: String = "https://maps.googleapis.com/maps/api";

    constructor(private HttpClient: HttpClient, private ProjectService: ProjectService) {

    }

    // CARREGAMENTO INICIAL DO GOOGLE MAPS
    async Init() {

        if (this.loadedGoogleMaps) return Promise.resolve(this.loadedGoogleMaps);

        const googleKey = this.ProjectService.GetSettings()?.GoogleMaps?.Key || '';

        return new Promise((resolve, reject) => {
            if (this.loadedGoogleMaps) {
                // If already loaded, resolve immediately
                resolve(true);
                return;
            }

            // Create the script element
            const script = document.createElement('script');
            script.src = `${this.googleMapsUrl}/js?libraries=geometry&sensor=false&key=${googleKey}&region=PT&callback=initMap`;
            script.async = true;
            script.defer = true;

            // Set the global callback function
            (window as any).initMap = () => {
                this.loadedGoogleMaps = true;
                this.ApiLoaded.next(true);
                resolve(true);
            };

            // Handle script load error
            script.onerror = () => {
                reject('Google Maps script load error');
                this.loadedGoogleMaps = true;
                this.ApiLoaded.next(false);
            };

            // Append the script to the document
            document.head.appendChild(script);
        });
    }

    /**
     * DEVOLVE OBSERVABLE DO MAPA
     * @returns 
     */
    GetMap() {
        return this.apiLoaded;
    }



    /**
     * 
     * @param style 
     * @returns 
     */
    GetMapStyle(style: EnumMapStyle.DEFAULT | EnumMapStyle.DARK | EnumMapStyle.LIGHT = EnumMapStyle.DEFAULT): google.maps.MapTypeStyle[] {
        //SETUP STYLE FROM JSON FILE
        // return new google.maps.StyledMapType(JSON.parse(JSON.stringify(EmbedJSONMapStyle)), { "name": 'StyledMap' });

        return (style == EnumMapStyle.DARK) ? EmbedJSONMapStyleDark : (style == EnumMapStyle.LIGHT) ? EmbedJSONMapStyleLight : EmbedJSONMapStyle;
    }

    /**
     * 
     * @param street 
     * @returns 
     */
    async GetCoordenatesFromStreetName(street: string = "") {

        const googleKey = this.ProjectService.GetSettings()?.GoogleMaps?.Key || '';

        // https://maps.google.com/maps/api/geocode/json?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA
        const http = this.HttpClient.get<any>(`${this.googleMapsUrl}/geocode/json?address=${street}&key=${googleKey}&region=PT`);

        return firstValueFrom(http)
            .then((response: any) => {
                console.log(`Result GET : `, response);

                return response?.status == 'OK' ? response?.results : [];
            })
            .catch(error => {
                console.log(`Error GET : `, error);

                return [];
            });
    }

}
