import { IonicModule } from '@ionic/angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropperModalComponent } from './image-cropper/image-cropper.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { MenuSelectModalComponent } from './menu-select/menu-select.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectUserProfileModalComponent } from './select-user-profile/select-user-profile.component';
import { SelectFileUploadModalComponent } from './select-file-upload/select-file-upload.component';
import { ComponentsModule } from '@components/components.module';
import { CameraModalComponent } from './camera/camera.component';
import { PresentImageModalComponent } from './present-image/present-image.component';
import { ItemSelectModalComponent } from './item-select/item-select.component';
import { PresentIframeComponent } from './present-iframe/present-iframe.component';
import { PipesModule } from '../pipes/pipes.module';
import { ObservationTextComponent } from './observation-text/observation-text.component';
import { IssueCardComponent } from './issue-card/issue-card.component';
import { SimpleListModalComponent } from './simple-list/simple-list.component';
import { DirectivesModule } from '@directives/directives.module';
import { SelectMerchantCampaignModalComponent } from './select-merchant-campaign/select-merchant-campaign.component';
import { ConsumptionChoiceModalComponent } from './consumption-choice/consumption-choice.component';
import { NotificationModalComponent } from './notification/notification.component';

const components = [
	ImageCropperModalComponent,
	// MenuSelectModalComponent,
	ItemSelectModalComponent,
	SelectUserProfileModalComponent,
	SelectFileUploadModalComponent,
	CameraModalComponent,
	PresentImageModalComponent,
	PresentIframeComponent,
	ObservationTextComponent,
	IssueCardComponent,
    SimpleListModalComponent,
    SelectMerchantCampaignModalComponent,
    ConsumptionChoiceModalComponent,
    NotificationModalComponent
];

@NgModule({
	declarations: [components],
	exports: [components],
	imports: [
		CommonModule,
		IonicModule,
		ImageCropperModule,
		ReactiveFormsModule,
		ComponentsModule,
		PipesModule,
        DirectivesModule
	]
})
export class ModalsModule { }
