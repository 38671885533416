import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { INotificationModel } from '@models/Notifications';
import { EnumGenericRoleActionAlerts } from '@pages/BasePage';
import { DateUtils } from '@utils/DateUtils';

@Component({
    selector: 'modal-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
})
export class NotificationModalComponent implements OnInit {

    public Data: INotificationModel;

    private getdata: Function;

    private markAsUnreaded: Function;

    public Form: FormGroup;

    public MarkHasntView: boolean = false;


    constructor(private NavParams: NavParams,
        private ModalController: ModalController) {

        this.getdata = this.NavParams?.get("getData");

        this.markAsUnreaded = this.NavParams?.get("markAsUnreaded");

    }

    async ngOnInit() {

        if (this.getdata)
            this.Data = await this.getdata();


    }


    OnChangeCheckbok() {
        this.MarkHasntView = !this.MarkHasntView;
    }

    async Close() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data

        // debugger;

        if (this.markAsUnreaded && this.MarkHasntView) {
            const response = await this.markAsUnreaded();

            if (response)
                this.ModalController.dismiss(true, EnumGenericRoleActionAlerts.Confirm);

            console.log("response not comp: ", response);
        } else {
            this.ModalController.dismiss(false, EnumGenericRoleActionAlerts.Close);
        }



    }

}
