import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { IFileModel } from '@models/Competition';
import { EnumGenericRoleActionAlerts } from '@pages/BasePage';
import { FileUtils } from '@utils/FileUtils';

@Component({
    selector: 'app-select-file-upload',
    templateUrl: './select-file-upload.component.html',
    styleUrls: ['./select-file-upload.component.scss'],
})
export class SelectFileUploadModalComponent implements OnInit {

    private onChange: Function;
    private onUpload: Function;

    public File: IFileModel;
    public FileTemplate: string;
    public Modules: { Id: number, Name: string }[] = [];

    public SelectedModule: { Id: number, Name: string } = null;

    public FileFormatsAllowed: string = '.xlsx';

    constructor(
        private NavParams: NavParams,
        private ModalController: ModalController
    ) {

        this.onChange = this.NavParams?.get('onChange') || null;
        this.onUpload = this.NavParams?.get('onUpload') || null;
        this.FileTemplate = this.NavParams?.get('fileTemplate') || '';
        this.Modules = this.NavParams?.get('modules') || [];

        this.FileFormatsAllowed = this.NavParams?.get('formatsAllowed') || '.xlsx';

        console.log(this.Modules);

        if (this.Modules?.length == 1)
            this.SelectedModule = this.Modules[0];

    }

    ngOnInit() { }

    async OnChangeFile(file: any) {

        console.log(file);

        if (this.onChange)
            this.File = await this.onChange(file);

        // if (!FileUtils.VerifyFileExtension(file, ['.xlsx'])) {
        // 	return this.ShowAlert('Aviso', 'Ficheiro não é válido', [{
        // 		text: 'confirmar',
        // 		role: EnumGenericRoleActionAlerts.Confirm
        // 	}]);
        // }
        // else if (!FileUtils.VerifyFileSize(file, 1)) {
        // 	return this.ShowAlert('Aviso', 'Ficheiro ultrapassa o limite de 1mb', [{
        // 		text: 'confirmar',
        // 		role: EnumGenericRoleActionAlerts.Confirm
        // 	}]);
        // }

        // const fileBase64: any = await FileUtils.GetBase64(file?.files[0]);

        // const formFile: IFileModel =
        // {
        // 	Id: 0,
        // 	OriginalName: file.files[0].name,
        // 	Base64Data: FileUtils.RemoveMimeTypeBase64(fileBase64),
        // 	Type: '',
        // 	Path: ''
        // }

    }

    async OnUploadFile() {

        console.log(this.SelectedModule);


        if (this.onChange) {
            const response = await this.onUpload(this.File, this.SelectedModule?.Id);

            if (response)
                this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Close);

        }
    }

    ChangeModule(e) {
        console.log(e);
        this.SelectedModule = e?.detail?.value || null;
    }

    Close() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Close);
    }

}
