import { CanLeavePageGuard } from './guards/can-leave-page.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes =
    [
        {
            path: 'login',
            loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
        },
        {
            path: 'initialize',
            loadChildren: () => import('./pages/initialize/initialize.module').then(m => m.InitializePageModule)
        },
        {
            path: 'dashboard',
            canActivate: [AuthGuard],
            loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
        },
        {
            path: 'news',
            canActivate: [AuthGuard],
            canActivateChild: [AuthGuard],
            loadChildren: () => import('./pages/news/news.module').then(m => m.NewsModule)
        },
        {
            path: 'content',
            canActivate: [AuthGuard],
            canActivateChild: [AuthGuard],
            loadChildren: () => import('./pages/content/content.module').then(m => m.ContentModule)
        },
        {
            path: 'settings',
            canActivate: [AuthGuard],
            loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
        },
        {
            path: 'configurations',
            canActivate: [AuthGuard],
            canActivateChild: [AuthGuard],
            loadChildren: () => import('./pages/configurations/configurations.module').then(m => m.ConfigurationsPageModule)
        },
        {
            path: 'users',
            canActivate: [AuthGuard],
            canActivateChild: [AuthGuard],
            loadChildren: () => import('./pages/users/users.module').then(m => m.UsersPageModule)
        },
        {
            path: 'account',
            canActivate: [AuthGuard],
            loadChildren: () => import('./pages/account/account.module').then(m => m.AccountPageModule),
        },
        {
            path: 'notifications',
            canActivate: [AuthGuard],
            canActivateChild: [AuthGuard],
            loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsPageModule)
        },
        {
            path: 'campaigns',
            canActivate: [AuthGuard],
            canActivateChild: [AuthGuard],
            loadChildren: () => import('./pages/campaigns/campaigns.module').then(m => m.CampaignsPageModule)
        },
        {
            path: 'categories',
            canActivate: [AuthGuard],
            canActivateChild: [AuthGuard],
            loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesPageModule)
        },
        {
            path: 'consumers',
            canActivate: [AuthGuard],
            canActivateChild: [AuthGuard],
            loadChildren: () => import('./pages/consumers/consumers.module').then(m => m.ConsumersPageModule)
        },
        {
            path: 'consumers-wallet',
            canActivate: [AuthGuard],
            canActivateChild: [AuthGuard],
            loadChildren: () => import('./pages/consumers/consumers.module').then(m => m.ConsumersPageModule)
        },
        {
            path: 'merchants',
            canActivate: [AuthGuard],
            canActivateChild: [AuthGuard],
            loadChildren: () => import('./pages/merchants/merchants.module').then(m => m.MerchantsPageModule)
        },
        {
            path: 'benefits',
            canActivate: [AuthGuard],
            canActivateChild: [AuthGuard],
            loadChildren: () => import('./pages/benefits/benefits.module').then(m => m.BenefitsPageModule)
        },
        {
            path: 'recovery',
            loadChildren: () => import('./pages/recovery-password/recovery-password.module').then(m => m.RecoveryPasswordPageModule)
        },

        {
            path: 'error',
            loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorPageModule)
        },

        {
            path: '**',
            redirectTo: '/error',
            data: {}
        },



    ];


@NgModule(
    {
        imports:
            [
                RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
            ],
        providers: [CanLeavePageGuard],
        exports: [RouterModule]
    })
export class AppRoutingModule { }