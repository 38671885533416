export interface ICoordinates {
    Latitude: string;
    Longitude: string;
}

export class Coordinates implements ICoordinates {
    public Latitude: string;
    public Longitude: string;

    constructor(data: Partial<ICoordinates> = {}) {
        this.Latitude = data.Latitude || "";
        this.Longitude = data.Longitude || "";
    }
}


export interface ILocationModel {
    Address?: string;
    ZipCode?: string;
    Locality?: string;
    Street?: string;
    District?: string;
    County?: string;
    Coordinates?: ICoordinates;
    Id?: number;
    Name?: string;
    RegistDate?: string;
    RegistUser?: number;
}

export class LocationModel implements ILocationModel {
    Address?: string;
    ZipCode?: string;
    Locality?: string;
    Street?: string;
    District?: string;
    County?: string;
    Coordinates: ICoordinates;
    Id?: number;
    Name?: string;
    RegistDate?: string;
    RegistUser?: number;

    constructor(data: Partial<LocationModel> = {}) {

        this.Address = data?.Address || null;
        this.ZipCode = data?.ZipCode || null;
        this.Locality = data?.Locality || null;
        this.Street = data?.Street || null;
        this.District = data?.District || null;
        this.County = data?.County || null;
        this.Name = data?.Name || null;
        this.Coordinates = data?.Coordinates || null;
        this.Id = data?.Id || null;

    }
}

export interface IKeyValue {
    Key: string,
    Value: string
}

export interface IContactModel {
    Id?: string;
    Phone?: string;
    MobilePhone?: string;
    Email: string;
    CreateDate?: string;
}

export interface IEntityModel {
    Id: string;
    Name: string;
    IdLocation?: string;
    IdContact?: string;
    IsSynchronized?: boolean;
    SynchronizedDate?: string;
    ShortName?: string;
    IsMain?: boolean;
    Location?: any;
    Contact?: any;
    Details?: any;
    EmailConfiguration?: string;
    MessageConfiguration?: string;
    CreateDate?: string;
}

export enum EnumPlacesType {
    Continent = '1',
    Country = '2',
    District = '3',
    County = '4',
    Parish = '5'
}

export enum EnumDownloadFormatType {
    Unknown = 0,
    Excel = 1,
    PDF = 2
}

// Ver estados comerciante
// export enum EnumStatus {
//     Pending = 1,
//     Accepted = 2,
//     Rejected = 3,
//     PendingResponsibleApproval = 4,
//     Finished = 5,
//     InProgress = 6,
//     Canceled = 7
// }

export enum EnumAttachmentsTypes {
    // Default
    NotDefined = 1,
    Image = 2,

    // Specific
    PermanentCertificateRegistration = 101,
    BeginningOfActivityDeclaration = 102,
    NoDebtToSocialSecurityDeclaration = 103,
    NoDebtToTaxAuthoritySecurityDeclaration = 104,
    SocialSecurityDeclaration = 105,
    BankAccountNumberProof = 106
}


/**
 * Default page number for the component of pagination
 */
export enum EnumPageRow {
    DEFAULT = 25,
    R25 = 25,
    R50 = 50,
    R100 = 100,
}

export enum EnumCurrencyCollection {
    /* [ Coins ]  */
    // [EnumCurrencyData(Id = (long)EnumCurrencyCollection.Euro, Name = "Euro", LangResource = "EuropeanUnionCurrency", Acronym = "EUR", Symbol = "€", Format = "C2", IsMain = true, IsBase = false, IsCoin = true)]
    Euro = 1,
    // [EnumCurrencyData(Id = (long)EnumCurrencyCollection.GB_Libra, Name = "Libra", LangResource = "BritishPound", Acronym = "GBP", Symbol = "£", Format = "C2", IsMain = false, IsBase = false, IsCoin = true)]
    GB_Libra = 2,
    // [EnumCurrencyData(Id = (long)EnumCurrencyCollection.US_Dollar, Name = "Dollar", LangResource = "AmericanDollar", Acronym = "USD", Symbol = "$", Format = "C2", IsMain = false, IsBase = true, IsCoin = true)]
    US_Dollar = 3,

    /* [ Credits ]  */
    // [EnumCurrencyData(Id = (long)EnumCurrencyCollection.Points, Name = "Points", LangResource = "Points", Acronym = "Pts", Symbol = "", Format = "C2", IsMain = false, IsBase = false, IsCoin = false)]
    Points = 1001,
}

export enum EnumDocumentType {

    NotDefined = 1,
    Image = 2,

    CC = 3,
    BI = 4,
    PASSPORT = 5,

    QRCodeMerchant = 21,
    PermanentCertificateRegistration = 101,
    BeginningOfActivityDeclaration = 102,
    NoDebtToSocialSecurityDeclaration = 103,
    NoDebtToTaxAuthoritySecurityDeclaration = 104,
    SocialSecurityDeclaration = 105,
    BankAccountNumberProof = 106,
    IdGovProof = 107,
    InvoiceDocument = 108,
    InvoiceRefundDocument = 109,
    CentralRegisterBeneficialOwner = 110
}

export enum EnumWalletModules {
    SocialWallet = 200,
    MunicipalCard = 300,
    PessoalCard = 301
}