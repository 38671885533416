import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { EnumGenericRoleActionAlerts } from '@pages/BasePage';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-consumption-choice',
    templateUrl: './consumption-choice.component.html',
    styleUrls: ['./consumption-choice.component.scss'],
})
export class ConsumptionChoiceModalComponent implements OnInit, OnDestroy {

    public SubHeaderText: string = "";
    public IsDisabled: boolean = false;
    public Value: number = null;

    private onSave: Function = null;
    private onShowToast: Function = null;

    public Form: FormGroup;
    private subs: Subscription[] = [];

    constructor(
        private ModalController: ModalController,
        private NavParams: NavParams
    ) {

        this.SubHeaderText = this.NavParams.get('subHeaderText') || this.SubHeaderText;
        // this.InputTitle = this.NavParams.get('inputTitle') || this.InputTitle;
        this.IsDisabled = this.NavParams.get('isDisabled') || this.IsDisabled;
        this.Value = this.NavParams.get('value') || this.Value;

        this.onSave = this.NavParams.get('onSave') || this.onSave;
        this.onShowToast = this.NavParams.get('showToast') || this.onShowToast;

        this.onCreateFormElement();
    }


    ngOnInit() { }

    ngOnDestroy() {
        this.subs?.forEach(sub => sub?.unsubscribe());
    }

    private onCreateFormElement() {

        this.Form = new FormGroup({
            Description: new FormControl(null, []),
            tempDescription: new FormControl(null, []),
            NewValue: new FormControl(null, [Validators.max(this.Value)]),
            Value: new FormControl({ value: this.Value, disabled: true }, []),
            IsPartial: new FormControl(null, [Validators.required])
        });

        const subValue = this.Form.get('IsPartial').valueChanges.subscribe(value => {

            if (value)
                this.Form.get('NewValue').addValidators([Validators.required]);
            else
                this.Form.get('NewValue').removeValidators([Validators.required]);

            this.Form.get('NewValue').updateValueAndValidity();
        });

        this.subs.push(...[subValue]);

    }

    Close() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Close);
    }


    /**
     * 
     * @param ev 
     */
    public OnChangeTextQuill(ev: any) {

        this.Form.get('tempDescription').setValue(ev);

        if (ev) {
            if (JSON.stringify(this.Form.get('tempDescription').value) != JSON.stringify(this.Form.get('Description').value))
                this.Form.get('tempDescription').markAsDirty();

        }
    }




    /**
     * 
     * @param ev 
     */
    public async OnSave(ev: any) {

        console.log(this.Form);

        if (!this.onShowToast) return;

        if (!this.Form.dirty) {

            return this.onShowToast('Não foram encontradas alterações.');
        }

        this.Form.markAllAsTouched();

        if (this.Form.invalid) {

            return this.onShowToast('Preencha todos os campos assinalados.');
        }



        const data: { AmountApproved: number, Observation: string } = {
            Observation: (this.Form.get('tempDescription').value || ''),
            AmountApproved: this.Form.get('IsPartial').value ? this.Form.get('NewValue').value : this.Form.get('Value').value
        }

        if (this.onSave) {

            const response = await this.onSave(data);

            console.log(response);

            if (response)
                this.ModalController.dismiss(null, EnumGenericRoleActionAlerts.Confirm);


        }

    }
}
