<ion-header>
    <ion-toolbar class="ion-padding-end">
        <!-- <ion-title>{{HeaderTitle}}</ion-title> -->

        <ion-button title="fechar" style="margin-right: -16px;" fill="clear" color="dark" shape="round" slot="end"
            (click)="Close()">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>

    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">

    <ion-grid class="full ion-no-margin ion-no-padding">
        <form [formGroup]="Form">

            <ion-row *ngIf="SubHeaderText">
                <ion-col>
                    <p>{{SubHeaderText}}</p>
                </ion-col>
            </ion-row>

            <ion-row>

                <ion-col size="12">



                    <ion-radio-group formControlName="IsPartial">

                        <ion-item [disabled]="Form.get('IsPartial').disabled">
                            <ion-label position="stacked" required>Aprovar com valor parcial?</ion-label>
                            <ion-grid>
                                <ion-row>
                                    <ion-col>
                                        <ion-item lines="none" [disabled]="Form.get('IsPartial').disabled">
                                            <ion-label>Sim</ion-label>
                                            <ion-radio slot="start" [value]="true"></ion-radio>
                                        </ion-item>
                                    </ion-col>
                                    <ion-col>
                                        <ion-item lines="none" [disabled]="Form.get('IsPartial').disabled">
                                            <ion-label>Não</ion-label>
                                            <ion-radio slot="start" [value]="false"></ion-radio>
                                        </ion-item>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                        </ion-item>
                    </ion-radio-group>

                    <form-errors [control]="Form.get('IsPartial')"></form-errors>

                </ion-col>

                <ion-col size="6" class="ion-padding-end">

                    <ion-item class="full" lines="full" fill="outline">
                        <ion-label position="stacked" i18n=":@@modal_consumption_coice.value">Comparticipação
                        </ion-label>

                        <ion-input formControlName="Value"></ion-input>
                    </ion-item>

                    <form-errors [control]="Form.get('Value')"></form-errors>

                </ion-col>

                <ion-col class="ion-padding-start" size="6" [hidden]="!(Form.get('IsPartial').value==true)">

                    <ion-item class="full" lines="full" fill="outline">
                        <ion-label position="stacked" i18n=":@@modal_consumption_coice.new-value" required>Novo valor de
                            comparticipação
                        </ion-label>

                        <ion-input formControlName="NewValue"></ion-input>
                    </ion-item>

                    <form-errors [control]="Form.get('NewValue')"></form-errors>

                </ion-col>

                <ion-col size="12">

                    <ion-item class="full" lines="full" fill="outline"
                        [ngClass]="{'disabled': Form.get('description')?.disabled}">
                        <ion-label position="stacked" i18n=":@@modal_consumption_coice.observation">Observação
                        </ion-label>
                        <text-editor-quill [source]="Form.get('Description').value"
                            [disabled]="Form.get('Description')?.disabled" (callback)="OnChangeTextQuill($event)">
                        </text-editor-quill>
                    </ion-item>

                    <form-errors [control]="Form.get('tempDescription')"></form-errors>

                </ion-col>




            </ion-row>
        </form>
    </ion-grid>

</ion-content>
<ion-footer>
    <ion-toolbar class="ion-padding-horizontal">
        <ion-button style="margin-right: -16px;" slot="end" fill="solid" color="medium"
            i18n=":@@modal_consumption_coice.submit" (click)="OnSave($event)">Confirmar
        </ion-button>
    </ion-toolbar>
</ion-footer>