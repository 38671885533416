import { WebService } from '@services/web.service';
import { IFilterList } from '@models/Content';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnumAPIStatus } from '@api/AbstractAPI';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    private timerMiliseconds: number = 60000; //1min

    private notificationsCount: number = 0;

    public NotificationsCount: Observable<number>;

    private notificationsCountBehaviorSubject: BehaviorSubject<number>;

    private countLoop;

    constructor(private WebService: WebService,
        private SessionService: SessionService) {


        this.notificationsCountBehaviorSubject = new BehaviorSubject<number>(this.notificationsCount);

        this.NotificationsCount = this.notificationsCountBehaviorSubject.asObservable();

        this.NotificationsCount.subscribe((notificationsCount: number = 0) => {

            this.notificationsCount = notificationsCount;

        });
    }

    async Init() {

        await this.GetNotificationCount();

        this.startCountLoop();

    }

    /**
 * 
 */
    private startCountLoop() {

        this.stopCountLoop();

        this.countLoop = setInterval(() => {

            this.GetNotificationCount();

        }, this.timerMiliseconds);
    }

    /**
     * 
     */
    private stopCountLoop() {

        clearInterval(this.countLoop);

        this.countLoop = null;
    }

    /**
     * 
     */
    async GetNotificationCount() {

        const response = await this.GetCounter();

        this.notificationsCount = response?.Result;

        this.notificationsCountBehaviorSubject.next(this.notificationsCount);

        // remove interval if session loss or other error
        if (response?.Status != EnumAPIStatus.Success || !this.SessionService.HasSession())
            this.stopCountLoop();

    }

    private async GetCounter() {

        const response = await this.WebService.GetNotificationsCounter();

        return response;
    }

    /**
     * 
     * @param filters 
     * @returns 
     */
    async GetList(filters: IFilterList) {

        const result = await this.WebService.GetNotificationList(filters);

        return result;
    }
    /**
     * 
     * @param id 
     * @returns 
     */
    async GetDetail(id: number) {

        const result = await this.WebService.GetDetail(id);

        return result;

    }
    /**
     * 
     * @param id 
     * @returns 
     */
    async MarkAsUnreadedNotification(id: number) {

        const result = await this.WebService.MarkAsUnreadedNotification(id);

        return result;

    }
}
