import { ITeamsModel } from '@models/Competition';
import { IUserInfoModel } from '@models/User';
import { IAuthCredentials, IAuthModel, ISession } from '@models/AuthUser';
import { IAPIResponse, AbstractAPI, EnumAPIStatus, IAPIPaginationResponse } from '@api/AbstractAPI';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, filter } from 'rxjs';
import { IFilterClassification, IFilterList, IFilterPublicAssetsList } from '@models/Content';
import { IDashboardResult } from '@models/Dashboard';

@Injectable({
    providedIn: 'root'
})
export class WebService extends AbstractAPI {

    public serverSessionLossSubject: Subject<boolean>;

    public ServerSessionLoss: Observable<boolean>;

    constructor(protected HttpClient: HttpClient) {
        super(HttpClient);

        this.serverSessionLossSubject = new Subject<boolean>();

        this.ServerSessionLoss = this.serverSessionLossSubject.asObservable();
    }

    //#region AUTHENTICATION METHODS

    /**
     * 
     * @param data 
     * @returns 
     */
    async Login(data: IAuthCredentials): Promise<IAPIResponse<IAuthModel>> {

        // nop futuro para multiplos projetos será necessario passar a global de modo a identificar o utilizador para o projeto
        const submit =
        {
            // global: this.Global,
            // data: {
            username: data.Username,
            password: data.Password
            // }
        };

        try { return await this.RequestPOST<IAuthModel>("Auth/Login", submit); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async RecoverPassword(data: any): Promise<IAPIResponse<IUserInfoModel>> {

        try { return this.handleAuthResponse(await this.RequestPOST<IUserInfoModel>("Auth/RecoverPassword", data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async ResetPassword(data: any): Promise<IAPIResponse<IUserInfoModel>> {

        try { return this.handleAuthResponse(await this.RequestPOST<IUserInfoModel>("Auth/ResetPassword", data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async ChangePersonalData(data: any): Promise<IAPIResponse<IUserInfoModel>> {

        try { return this.handleAuthResponse(await this.RequestPOST<IUserInfoModel>("Auth/ChangeName", data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async ChangePassword(data: any): Promise<IAPIResponse<IUserInfoModel>> {

        try { return this.handleAuthResponse(await this.RequestPOST<IUserInfoModel>("Auth/ChangePassword", data)); }
        catch (error) { return error; }
    }


    /**
     * 
     * @returns 
     */
    async Logout(): Promise<IAPIResponse<boolean>> {

        try { return this.handleAuthResponse(await this.RequestGET<boolean>("Auth/Logout")); }
        catch (error) { return error; }
    }

    //#endregion

    //#region EDITION METHODS

    /**
     * 
     * @returns 
     */
    async GetBaseEditionList(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>("Common/CalendarYear/GetBaseList")); }
        catch (error) { return error; }
    }
    /**
     * 
     * @param filter 
     * @returns 
     */
    async GetEditionList(filter?: any): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>("Race/Edition/GetList", filter)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async GetEditionById(id: number): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Race/Edition/Get?id=${id}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param form 
     * @returns 
     */
    async SaveEdition(form): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Race/Edition/Save`, form)); }
        catch (error) { return error; }
    }

    async DeleteEdition(id: number): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Race/Edition/Delete?id=${id}`)); }
        catch (error) { return error; }
    }
    //#endregion

    //#region COMMON METHODS

    /**
     * 
     * @param formData 
     * @returns 
     */
    async UploadAndGetUrl(formData?: FormData): Promise<IAPIResponse<string>> {

        try { return this.handleAuthResponse(await this.RequestFilesPOST<string>("Generic/File/UploadAndGetUrl", formData)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetGenreList(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Generic/Gender/GetList`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idCalendarYear 
     * @returns 
     */
    async GetDashboardData(idCalendarYear?: string): Promise<IAPIResponse<IDashboardResult>> {

        // ?idCalendarYear=${idCalendarYear}
        try { return this.handleAuthResponse(await this.RequestGET<IDashboardResult>(`Dashboard/Get`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetPlatformList(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Generic/GetPlatformList`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetAvailableLanguages(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Generic/Language/GetList`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetEntityList(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Common/Entity/GetList`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param searchText 
     * @param idContinent 
     * @returns 
     */
    async GetCountryList(searchText: string, idContinent: number): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Generic/Country/GetList?searchText=${(searchText || '')}&idContinent=${(idContinent || '')}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idCampaign 
     * @param filters 
     * @returns 
     */
    async GetBlackListItemList(idCampaign, filters: any): Promise<IAPIResponse<any>> {

        const params = {};

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Common/Entity/BlackListItem/List/Get?idCampaign=${idCampaign}`, params)); }
        catch (error) { return error; }
    }


    /**
     * 
     * @returns 
     */
    async GetOCRConfiguration(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Common/Entity/Configuration/OCR`)); }
        catch (error) { return error; }
    }


    /**
     * 
     * @param data 
     * @returns 
     */
    async SaveOCRConfiguration(data): Promise<IAPIResponse<any>> {


        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Common/Entity/Configuration/OCR/Save`, data)); }
        catch (error) { return error; }
    }
    //#endregion

    //#region MENUS

    /**
     * devolve a lista de menus que o utilizador tem acesso
     * @returns 
     */
    async GetNavigationList(): Promise<IAPIResponse<any[]>> {

        try { return this.handleAuthResponse(await this.RequestGET<any[]>("Menu/GetNavigationList")); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idParent 
     * @param idModule 
     * @returns 
     */
    async GetSimpleMenuListPermissions(idParent?: string, idModule?: number): Promise<IAPIResponse<any>> {

        const params: string = `idParent=${idParent || ''}&idModule=${idModule || ''}&isPublic=${''}&isTabNavigation=${''}`;

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Menu/GetSimpleList?${params}`)); }
        catch (error) { return error; }
    }

    /**
     * Devolve todas as permissoes criadas
     * @returns 
     */
    async GetMenuListModules(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>("Menu/GetModuleList")); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idModule 
     * @param idMenu 
     * @returns 
     */
    async GetOperationList(idModule?: string, idMenu?: string): Promise<IAPIResponse<any>> {

        // const params = `idModule=${idModule}&idMenu=${idMenu}`;

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Menu/GetOperationList?idModule=${idModule || ''}&idMenu=${idMenu || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetMenuTabList(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>("Menu/GetTabList")); }
        catch (error) { return error; }
    }
    /**
     * devolve lista de menus de todo projeto para configuração
     * @returns 
     */
    async GetMenuList(idPlatform?: number): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Menu/GetList?idPlatform=${idPlatform || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * devolve menu para configuração
     * @returns 
     */
    async GetMenu(id: number): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Menu/Get?id=${id || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async SaveMenu(data): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Menu/Save`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async DeleteMenu(id: number): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Menu/Delete?id=${id}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param menus 
     * @returns 
     */
    async ChangePositionMenu(menus: { Id: string, Position: number, IdParent?: string }[]): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Menu/ChangePosition`, menus)); }
        catch (error) { return error; }
    }

    //#endregion

    //#region CONTENT METHODS
    /**
     * @param filters
     * @returns 
     */
    async GetContentList(filters: IFilterList): Promise<IAPIPaginationResponse<any>> {


        const params = `PageNumber=${filters?.PageNumber || ''}
        &PageRows=${filters?.PageRows || ''}
        &Text=${filters.Text || ''}`;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Content/GetList?${params}`)); }
        catch (error) { return error; }
    }

    /**
     * @param id
     * @returns 
     */
    async DeleteContentItem(id: number): Promise<IAPIPaginationResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Content/Delete?id=${id}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async GetContentDetails(id: number, idEdition: number = 0): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Content/Get?id=${id}&idEdition=${idEdition || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * @param data
     * @returns 
     */
    async SaveContentData(data: any): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Content/Save`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetContentTypeList(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Content/Type/GetList`)); }
        catch (error) { return error; }
    }

    //#endregion

    //#region NOTIFICATIONS

    /**
     * @param filters
     * @returns 
     */
    async GetNotificationList(filters: IFilterList): Promise<IAPIPaginationResponse<any>> {

        const params = {
            WasViewed: filters?.WasViewed ?? '',
            SearchData: {
                PageNumber: filters?.PageNumber || '',
                PageRows: filters?.PageRows || '',
                Text: filters?.Text || '',
                OrderDescending: filters?.OrderDescending ?? '',
            }
        };

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Notification/GetList`, params)); }
        catch (error) { return error; }
    }


    /**
     * 
     * @param id 
     * @returns 
     */
    async GetDetail(id): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Notification/Get/${id}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async MarkAsUnreadedNotification(id): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Notification/Unread/Set/${id}`)); }
        catch (error) { return error; }
    }
    /**
     * 
     * @returns 
     */
    async GetNotificationsCounter(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Notification/User/UnreadCounter`)); }
        catch (error) { return error; }
    }

    async SendUserNotification(data: { observation: string, idPerson: string }): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Race/Edition/SendUserNotification`, data)); }
        catch (error) { return error; }
    }

    //#endregion

    //#region USERS

    /**
     * 
     * @returns 
     */
    async GetUserInfo(): Promise<IAPIResponse<IUserInfoModel>> {

        try { return this.handleAuthResponse(await this.RequestGET<IUserInfoModel>("User/GetInfo")); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetUsersList(filters: IFilterList): Promise<IAPIResponse<any[]>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any[]>(`User/List?PageNumber=${filters?.PageNumber || ''}&PageRows=${filters?.PageRows || ''}&Text=${filters.Text || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async GetUsersById(id: number): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any[]>(`User/Get?id=${id || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data
     * @returns 
     */
    async SaveUser(data: any): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any[]>(`User/Save`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async DeleteUser(id: number): Promise<IAPIResponse<any[]>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any[]>(`User/CancelAccount/${id || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetRoleUsersList(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`User/Role/GetList`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async GetRoleUserPermissionTemplate(id: string = null): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`User/Permissions/GetTemplate?id=${id || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idUserRole 
     * @returns 
     */
    async GetRoleUserPermissionListTemplate(idUserRole: number = null): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`User/Permissions/GetTemplateList?idUserRole=${idUserRole || ''}`)); }
        catch (error) { return error; }
    }


    /**
     * 
     * @param data 
     * @returns 
     */
    async SaveRoleUserPermissionTemplate(data: any): Promise<IAPIResponse<any>> {

        const params = data;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`User/Permissions/SaveTemplate`, params)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async DeleteRoleUserPermissionTemplate(id: string): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`User/Permissions/DeleteTemplate?id=${id || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async UserGenerateNewPassword(id: string): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`User/Credentials/GenerateNewPassword/${id}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async UserSendEmailToDefinePassword(id: string): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`User/Credentials/SendEmailToDefinePassword/${id}`)); }
        catch (error) { return error; }
    }
    //#endregion

    //#region REPORTS


    //#endregion

    //#region CAMPAIGN

    /**
     * 
     * @param id 
     * @returns 
     */
    async GetCampaign(id: string): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Campaign/Get?id=${id}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idEntity 
     * @returns 
     */
    async GetCampaignList(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Campaign/List/Get`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async SaveCampaign(data: any): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Campaign/Save`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async DeleteCampaign(id: string): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Campaign/Delete?id=${id}`)); }
        catch (error) { return error; }
    }

    async SaveCampaignFiles(idCampaign: string, data: any[]): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Campaign/File/SaveList?idCampaign=${idCampaign}`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idCampaign 
     * @returns 
     */
    async GetCampaignWalletList(idCampaign: string): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Campaign/GetCampaignWalletList/${idCampaign}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idCampaign 
     * @param filters
     * @returns 
     */
    async GetCampaignChargingList(idCampaign: string = '', filters: any): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Campaign/GetCampaignChargingList?idCampaign=${idCampaign || ''}`, filters)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idCampaignCharging 
     * @returns 
     */
    async GetCampaignChargingDetails(idCampaignCharging: string = ''): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Campaign/GetCampaignChargingDetails/${idCampaignCharging || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idCampaignCharging 
     * @param filters 
     * @returns 
     */
    async GetCampaignChargingUserList(idCampaignCharging: string = '', filters: any): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Campaign/GetCampaignChargingUserList/${idCampaignCharging || ''}`, filters)); }
        catch (error) { return error; }
    }

    /**
     * Guardar carregamento
     * @param data 
     * @returns 
     */
    async SaveCampaignCharging(data: any): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Campaign/SaveCampaignCharging`, data)); }
        catch (error) { return error; }
    }

    /**
     * Guardar carteiras para um carregamento
     * @param data 
     * @returns 
     */
    async SaveCampaignChargingWallet(data: any, idCampaignCharging: string): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Campaign/SaveCampaignChargingWallet/${idCampaignCharging}`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async GetAgreementCategory(id: string): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Campaign/AgreementCategory/Get?id=${id || ''}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async SaveAgreementCategory(data: any): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Campaign/AgreementCategory/Save`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetAgreementCategoryList(filters?): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Campaign/AgreementCategory/GetList`)); }
        catch (error) { return error; }
    }


    /**
     * 
     * @param data 
     * @returns 
     */
    async AgreementCategoryForcingListFirstSync(data: string[]): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Campaign/AgreementCategory/ForcingListSync`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idCampaignCharging 
     * @returns 
     */
    async GenerateCampaignChargingFile(idCampaignCharging: string): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Campaign/Payshop/CampaignChargingFile/Save?idCampaignCharging=${idCampaignCharging}`)); }
        catch (error) { return error; }
    }
    /**
     * 
     * @param idCampaignCharging 
     * @returns 
     */
    async DeleteCampaignCharging(idCampaignCharging: string): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Campaign/CampaignCharging/Delete/${idCampaignCharging}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idCampaignCharging 
     * @returns 
     */
    async SubmitCampaignCharging(idCampaignCharging: string): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Campaign/SubmitCampaignCharging/${idCampaignCharging}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idCampaignCharging 
     * @returns 
     */
    async SendPayshopCampaignChargingFile(idCampaignCharging: string): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Campaign/Payshop/CampaignChargingFile/UploadPayshop?idCampaignCharging=${idCampaignCharging}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idCampaignCharging 
     * @returns 
     */
    DownloadCampaignChargingFile(idCampaignCharging: string): Observable<any> {

        try { return this.RequestPOSTDownloadFile(`Campaign/ChargingFile/Download/${idCampaignCharging}`); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idCategory 
     * @returns 
     */
    GetMerchantListAssociated(idCategory: string): Promise<IAPIResponse<any>> {

        try { return this.RequestGET(`Campaign/AgreementCategory/Merchant/GetList?idCategory=${idCategory}`); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    SaveMerchantListAssociated(data: any): Promise<IAPIResponse<any>> {

        try { return this.RequestPOST(`Campaign/MerchantEligible/List/Save`, data); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    DeleteMerchantListAssociated(data: any): Promise<IAPIResponse<any>> {

        try { return this.RequestPOST(`Campaign/MerchantEligible/List/Delete`, data); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idMerchant 
     * @returns 
     */
    async GetMerchantCampaignList(idMerchant: string): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Merchant/Campaign/GetList/${idMerchant}`)); }
        catch (error) { return error; }
    }
    //#endregion


    //#region MERCHANTS

    /**
     * 
     * @param id 
     * @returns 
     */
    async GetMerchant(id: string, idVersion?: string): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Merchant/Get/${id}${idVersion ? '?idMerchantVersion=' + idVersion : ''}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetMerchantList(filters: IFilterList, status: number[] = []): Promise<IAPIPaginationResponse<any>> {

        const params = {
            searchData: {
                pageNumber: filters.PageNumber || 1,
                pageRows: filters.PageRows || 25,
                orderDescending: filters.OrderDescending || true,
                // "orderingField": 0,
                text: filters.Text || "",
                // "type": "",
                // "startDate": "",
                // "endDate": "",
            },
            statusList: status,
            idEconomicActivityCodeCategory: "", // mcc
            economicActivityCodeList: [], // cae
            idModule: filters?.IdModule || null
        };

        // ?${params}
        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Merchant/GetResumeStatusList`, params)); }
        catch (error) { return error; }
    }

    /**
     * guardar dados do comerciante
     * @param data 
     * @returns 
     */
    async SaveMerchant(data): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Merchant/Save`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async DeleteMerchant(id: string): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Merchant/Delete?id=${id}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idMerchant 
     * @param data 
     * @returns 
     */
    async SaveMerchantFiles(idMerchant: string, data: any[]): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Merchant/File/SaveList?idMerchant=${idMerchant}`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param idFile 
     * @returns 
     */
    async DeleteMerchantFile(idFile: string): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Merchant/File/Delete`, JSON.stringify(idFile))); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param file 
     * @returns 
     */
    async MerchantFileDefineMain(file: { idMerchant: string, idMerchantFile: string, isMain: boolean }): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Merchant/File/DefineMain`, file)); }
        catch (error) { return error; }
    }
    /**
     * 
     * @param includeAlreadyConnectedUsers 
     * @param idCurrentMerchant 
     * @returns 
     */
    async SearchUserListManager(text?: string, includeAlreadyConnectedUsers?: boolean, idCurrentMerchant?: string): Promise<IAPIResponse<any>> {

        const params = `
			text=${text || ''}
			&includeAlreadyConnectedUsers=${includeAlreadyConnectedUsers ?? false}
			&idCurrentMerchant=${idCurrentMerchant || ''}
		`;

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Merchant/Manager/SearchUserList?${params}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async SaveListManager(data: { IdMerchant: string, UserList: any[] }): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Merchant/Manager/SaveList`, data)); }
        catch (error) { return error; }
    }

    /**
     * Importar lista de novos comerciantes
     * @param formData 
     * @param idModule
     * @returns 
     */
    async ImportMerchantList(formData: FormData, idModule?: string): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestFilesPOST<any>(`Request/ImportMerchantList?idModule=${idModule || ''}`, formData)); }
        catch (error) { return error; }
    }

    /**
      * Importar lista de consumos dos comerciantes por SAF-T
      * @param formData 
      * @param idModule
      * @returns 
      */
    async ImportMerchantSAFT(formData: FormData): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestFilesPOST<any>(`Request/Invoices/ImportSaftFile`, formData)); }
        catch (error) { return error; }
    }
    /**
     * 
     * @param id 
     * @returns 
     */
    async MerchantForcingFirstSync(id): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any[]>(`Merchant/ForcingSync/${id}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async MerchantAcceptApplication(data): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Merchant/Status/Accepted`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async MerchantRejectApplication(data): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Merchant/Status/Rejected`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetListMCCs(): Promise<IAPIResponse<any[]>> {

        try { return this.handleAuthResponse(await this.RequestGET<any[]>(`Merchant/MCC/GetList`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetListCAEs(): Promise<IAPIResponse<any[]>> {

        try { return this.handleAuthResponse(await this.RequestGET<any[]>(`Merchant/CAE/GetList`)); }
        catch (error) { return error; }
    }


    /**
     * 
     */
    async GetAvailableMerchantList(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Merchant/AvailableList`)); }
        catch (error) { return error; }
    }

    //#endregion

    //#region POINTS OF SELL

    /**
     * 
     * @param text 
     * @param idMerchant 
     * @param idModule 
     * @returns 
     */
    async SearchConsumerByNumber(text: string, idMerchant: string, idModule: string = ''): Promise<IAPIResponse<any>> {

        const params = {
            cardNumber: String(text),
            idMerchant: String(idMerchant),
            idModule: String(idModule)
        };


        try { return this.handleAuthResponse(await this.RequestPOST<any>(`PointOfSales/GetAvailableModuleList`, params)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param text 
     * @param idMerchant 
     * @param idModule 
     * @returns 
     */
    async GetAvailableCampaignList(text: string, idMerchant: string, idModule: string = ''): Promise<IAPIResponse<any>> {

        const params = {
            cardNumber: String(text),
            idMerchant: String(idMerchant),
            idModule: String(idModule)
        };


        try { return this.handleAuthResponse(await this.RequestPOST<any>(`PointOfSales/GetAvailableCampaignList`, params)); }
        catch (error) { return error; }
    }
    /**
     * 
     * @param data 
     * @returns 
     */
    async MerchantRegistConsumption(data): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestFilesPOST<any>(`PointOfSales/RegistConsumption`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async MerchantConfirmConsumption(data): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`PointOfSales/ConfirmConsumption`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async MerchantUpdateConsumption(data): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestFilesPOST<any>(`PointOfSales/UpdateConsumption`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async MerchantSubmitConsumption(data): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Merchant/Consumption/Submit`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param filters 
     * @returns 
     */
    async GetMerchantConsumptionsList(filters: IFilterList): Promise<IAPIPaginationResponse<any>> {

        const params = `includeOCRInfo=${filters?.includeOCRInfo ?? false}
						&idCampaign=
						&idStatus=${filters?.IdStatus || ''}`;

        const filter =
        {
            pageNumber: filters?.PageNumber,
            pageRows: filters?.PageRows,
            orderDescending: filters.OrderDescending,
            text: filters?.Text,
            // "type": ",
            // "date": "string",
            // "startDate": "string",
            // "endDate": "string"
        }

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`PointOfSales/GetConsumptionList?${params}`, filter)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async GetMerchantConsumption(id: string): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`PointOfSales/GetConsumption/${id}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param filters 
     * @returns 
     */
    async GetMerchantAllConsumptionsList(filters: IFilterList): Promise<IAPIPaginationResponse<any>> {

        const params = `PageNumber=${filters?.PageNumber || ''}
						&PageRows=${filters?.PageRows || ''}
						&OrderDescending=${filters?.OrderDescending || true}
						&OrderingField=${filters?.OrderingField || ''}
						&Text=${filters?.Text || ''}
						&StartDate=${filters?.StartDate || ''}
						&EndDate=${filters?.EndDate || ''}
						&idCard=
						&idCampaign=
						&isGenericExpense=
						&idType=${filters?.IdType || ''}
						&idStatusList=${filters.IdStatus || ''}`;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Wallet/Movement/GetList?${params}`)); }
        catch (error) { return error; }
        // try { return this.handleAuthResponse(await this.RequestPOST<any>(`Wallet/Movement/List/Resume/Get?${params}`)); }
        // catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async GetMerchantConsumptionsListAccepted(data): Promise<IAPIPaginationResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Common/Entity/Consumption/Accepted`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async GetMerchantConsumptionsListRejected(data): Promise<IAPIPaginationResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Common/Entity/Consumption/Rejected`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async GetMerchantConsumptionIntention(id): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`PointOfSales/GetConsumptionIntention/${id}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async GetMerchantModuleList(id): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Merchant/Module/GetList/${id}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @returns 
     */
    async GetAvailableWalletModuleList(): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Merchant/GetAvailableWalletModuleList`)); }
        catch (error) { return error; }
    }
    /**
     * 
     * @param id 
     * @param data
     * @returns 
     */
    async ConfigureMerchantModule(id, data: number[]): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Merchant/Module/Configure/${id}`, data)); }
        catch (error) { return error; }
    }
    //#endregion

    //#region CONSUMERS

    /**
     * 
     * @param filters 
     * @param includeUserWithoutWallet 
     * @returns 
     */
    async GetConsumersWalletResumeList(filters, includeUserWithoutWallet: boolean = true): Promise<IAPIPaginationResponse<any>> {

        const queryparams = `?includeUserWithoutWallet=${includeUserWithoutWallet}&idModule=${filters?.IdModule || ''}`;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Wallet/GetResumeList${queryparams}`, filters)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async GetUserConsumer(id: string): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Wallet/User/Get/${id}`)); }
        catch (error) { return error; }
    }
    /**
     * 
     * @param data 
     * @returns 
     */
    async SaveUserConsumer(data): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Wallet/SaveUserWallet`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async ConsumerIssueNewCard(data): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Wallet/IssueNewCard`, data)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async ConsumersWAlletForcingFirstSync(data): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Wallet/ForcingCreateSyncWalletList`, data)); }
        catch (error) { return error; }
    }

    /**
     * Importar lista de novos comerciantes
     * @param formData 
     * @param idModule 
     * @returns 
     */
    async ImportConsumersList(formData: FormData, idModule): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestFilesPOST<any>(`Request/ImportConsumerList?idModule=${idModule || ''}`, formData)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param filters 
     * @returns 
     */
    async GetConsumersWalletPendingList(filters): Promise<IAPIPaginationResponse<any>> {

        const queryparams = `?IdModule=${filters?.IdModule || ''}&IdStatus=${filters?.IdStatus || ''}`;

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Wallet/GetModuleApplicationStatusList${queryparams}`, filters)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    async GetConsumersWalletPending(id, idModule?: string, idStatus?: string): Promise<IAPIPaginationResponse<any>> {

        const queryparams = `?IdModule=${idModule || ''}&IdStatus=${idStatus || ''}`;

        try { return this.handleAuthResponse(await this.RequestGET<any>(`Wallet/GetModuleApplicationStatus/${id}${queryparams}`)); }
        catch (error) { return error; }
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    async ConsumerChangeBalanceUsageStatus(data): Promise<IAPIResponse<any>> {

        try { return this.handleAuthResponse(await this.RequestPOST<any>(`Wallet/ChangeBalanceUsageStatus`, data)); }
        catch (error) { return error; }
    }
    //#endregion
    /**
     * UTILIZAR APENAS EM METODOS SEGUROS
     * @param response 
     * @returns 
     */
    private async handleAuthResponse<T>(response: IAPIResponse<T>) {

        if (!response) return;

        console.log("handler -> ", response.Status);

        if (!response.IsSuccessStatus
            && (response.Status == EnumAPIStatus.InvalidAccessToken
                // 
                // || response.Status == EnumAPIStatus.Unauthorized
            )) {

            this.serverSessionLossSubject.next(true);
        }

        return response;
    }
}
